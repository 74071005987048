<template>
	<basePopup
		:okFunc="goAction"
		:okText="isEdit ? '수정하기' : '등록하기'"
		:okVariant="isEdit ? 'info' : 'primary'"
		:title="kind + ' 템플릿 ' + apiKind"
	>
		<div v-for="(v, i) in info" :key="v + i" class="flex-between">
			<p :class="v.isTextArea ? 'py-20 -mb-3' : 'py-3'" v-text="v.label" />

			<b-form-input
				v-if="!v.model"
				class="w-4/5 top-1.5"
				v-model.trim="input[v.key]"
				:name="v.label"
				:placeholder="v.label + ' 입력해주세요'"
				@keyup.enter=""
				:disabled="v.isDisabled"
			/>
			<b-form-textarea
				v-else-if="v.model == 'textArea'"
				class="w-4/5 resize-none top-1.5 h-40 mb-3"
				v-model.trim="input[v.key]"
				:placeholder="v.label + ' 입력해주세요'"
			/>
			<div v-else-if="v.model == 'radioBtn'" class="flex w-4/5">
				<b-form-radio
					v-for="r in opts[v.key]"
					class="mr-4 py-3"
					v-model="input[v.key]"
					:name="'radio' + i"
					:value="r.val"
				>
					{{ r.label }}
				</b-form-radio>
			</div>
			<component :is="comp" v-else-if="v.model == 'additionalData'" class="w-4/5" :pr="ths">
				<template #default="{ data }" class="flex">
					<b-form-input
						v-for="(k, i) in ['title', 'url']"
						class="w-2/5 mr-3"
						v-model="data.v[k]"
						:name="k + i"
						:placeholder="k + '을 입력해주세요'"
					/>
				</template>
			</component>
		</div>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'

export default {
	components: { basePopup, addItem: () => import('comp/local/addItem') },
	data() {
		return {
			kind: '',
			isShow: false,
			isEdit: false,
			info: [],
			input: {},
			opts: {},
			baseData: {},
			apiUrl: '',
			saveApi: '',
			apiKind: '',
			comp: '',
		}
	},
	methods: {
		open(kind, isEdit = false, item) {
			this.input = {}
			this.isEdit = isEdit
			let info
			switch (kind) {
				case 'talk':
					this.kind = '알림톡'
					info = [
						{ label: '메시지 key', key: 'msgKey', mapKey: 'messageKey' },
						{ label: '템플릿 코드', key: 'templateCode' },
						{ label: '제목', key: 'title', mapKey: 'subject' },
						{ label: '내용', key: 'content', model: 'textArea' },
						{ label: '카카오버튼 구분', key: 'kkoBtnType', mapKey: 'kakaoButtonType', isOptional: 1 },
						{ label: '카카오버튼 내용', key: 'kkoBtnInfo', mapKey: 'kakaoButtonInfo', isOptional: 1 },
					]
					this.apiUrl = isEdit
						? '/operatingHandle/updateTemplateSendMsgEntity'
						: '/operatingHandle/insertTemplateSendMsgEntity'
					break
				case 'email':
					this.kind = '이메일'
					info = [
						{ label: '이메일 key', key: 'emailKey' },
						{ label: '제목', key: 'title', mapKey: 'subject' },
						{ label: '내용', key: 'content', model: 'textArea' },
					]
					this.apiUrl = isEdit
						? '/operatingHandle/updateTemplateEmailEntity'
						: '/operatingHandle/insertTemplateEmailEntity'
					break
				case 'appPush':
					this.kind = '앱푸시'
					info = [
						{ label: '앱푸시 SEQ', key: 'pushNotificationTemplateSeq' },
						{ label: '템플릿 코드', key: 'template' },
						{ label: '컨텐츠 유형', model: 'radioBtn', key: 'contentType' },
						{ label: '템플릿 이름', key: 'name' },
						{ label: '제목', key: 'title' },
						{ label: '내용', key: 'content', model: 'textArea' },
						{ label: '더 알아보기', model: 'additionalData', isOptional: 1 },
						{ label: '랜딩페이지', key: 'url' },
						{ label: '알림함 랜딩페이지', key: 'inboxUrl' },
					]
					if (!isEdit) {
						this.input.contentType = 0
						this.input.content = '\r\n*수신거부 : 마이핫트>알림설정'
						info.splice(0, 1)
					}
					this.apiUrl = isEdit
						? 'api/backoffice/pushNotificationHandle/updateTemplate'
						: 'api/backoffice/pushNotificationHandle/insertTemplate'

					this.opts.contentType = [
						{ label: '판매 활동', val: 0 },
						{ label: '광고성', val: 1 },
						{ label: '정보성', val: 2 },
					]
					this.baseData = {
						type: 0,
					}
					this.comp = 'addItem'
					setTimeout(() => {
						const def = { title: '', url: '' }
						this.addItem.defaultAddItemList = def
						if (item) {
							const json = JSON.parse(item.additionalData)
							if (json && Array.isArray(json.buttons)) this.addItem.addItemList = json.buttons
							else this.addItem.addItemList = [{ ...def }]
						} else this.addItem.addItemList = [{ ...def }]
					}, 100)
					break
			}

			if (isEdit) {
				info[0].isDisabled = true
				//서버에서 가져온 데이터 매핑
				info.map(v => {
					const key = v.mapKey ? v.mapKey : v.key
					this.input[v.key] = item[key]
				})
				this.saveApi = putApi
				this.apiKind = '수정'
			} else {
				this.saveApi = postApi
				this.apiKind = '등록'
			}

			this.info = info
			this.isShow = true
		},
		goAction() {
			let error = [],
				data = { ...this.baseData }
			this.info.map(v => {
				if (typeof this.input[v.key] == 'undefined' || this.input[v.key] === '') {
					if (v.isOptional) data[v.key] = null
					else error.push(v.label + ' 입력해주세요')
				} else data[v.key] = this.input[v.key]
			})
			if (error.length) alert.w(error)
			else {
				if (this.kind == '앱푸시') {
					const additionalData = this.addItem.addItemList.filter(v => v.title || v.url)
					if (additionalData.length) data.additionalData = JSON.stringify({ buttons: additionalData })
					else data.additionalData = ''
				}

				this.saveApi(this.apiUrl, data).then(res => {
					alert.s(this.apiKind + '되었습니다.')
					this.isShow = false
					this.p.changePage(this.$parent.lastOpt.paging.pageNo * 1 + 1)
				})
			}
		},
	},
}
</script>
