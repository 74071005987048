<template>
	<templateComp :apiUrl="apiUrl" :inf="inf" :kind="kind" :pr="ths" :searchKeyOpts="searchKeyOpts" />
</template>

<script>
import templateComp from 'pages/template'

export default {
	components: { templateComp },
	data() {
		return {
			kind: 'email',
			searchKeyOpts: [
				{ label: '이메일key', value: 'messageKey' },
				{ label: '제목', value: 'subject' },
				{ label: '내용', value: 'content' },
			],
			inf: [
				{ title: 'seq', model: 'idx', size: 2 },
				{ title: '이메일 key', key: 'emailKey', size: 2 },
				{ title: '제목', key: 'subject', size: 6 },
				{ title: '내용', key: 'content', size: 24 },
				{ title: '생성일', key: 'createDt', size: 6 },
				{ title: '수정일', key: 'updateDt', size: 6 },

				{
					title: '수정',
					model: 'button',
					icon: 'pencil-square',
					size: 2,
					func: item => {
						this.templateComp.templatePopup.open(this.kind, true, item)
					},
				},
			],
			apiUrl: '/operatingSelect/emailSendList',
		}
	},
	methods: {},
	created() {},
}
</script>
