<template>
	<div>
		<searchCard>
			<div class="flex">
				<div class="w-1/6 mr-4">
					<h6>검색기준</h6>
					<vSelect v-model="opt.kind" :clearable="false" :options="searchKeyOpts" />
				</div>
				<div class="w-1/2">
					<h6 class="w-1/1">검색어</h6>
					<b-form-input
						class="inline-block w-4/5"
						v-model.trim="opt.searchKeyword"
						placeholder="검색어"
						@keyup.enter="search"
					/>
					<b-button class="px-12 ml-4 -top-0.5" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>
		<b-card>
			<b-button class="mb-4" @click="templatePopup.open(kind)" variant="purple">템플릿 등록하기</b-button>
			<tb :inf="inf" :res="res" />
		</b-card>
		<templatePopup :pr="ths" cName="templatePopup" />
	</div>
</template>

<script>
import templatePopup from 'pages/templatePopup'

export default {
	components: { templatePopup },
	props: {
		cName: { default: 'templateComp' },
		kind: { default: '' },
		searchKeyOpts: { default: '' },
		inf: { default: '' },
		apiUrl: { default: '' },
	},
	data() {
		const defOpt = {
			kind: this.searchKeyOpts[0],
			searchKeyword: '',
		}
		return {
			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
			res: {},
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			const opt = this.opt

			this.lastOpt = {
				paging: getPaging(),
				saveStatus: 'Y',
				orderBy: getOrder('seqNo'),
			}

			if (opt.searchKeyword) this.lastOpt.searchKey = getSearchKey(getDropdownValue(opt.kind), opt.searchKeyword)
			return this.changePage(1)
		},
		changePage(page = 1) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi(this.apiUrl, this.lastOpt).then(res => {
				res.list = res.list.map(v => {
					v.createDt = v.createDt.replace('T', ' ')
					v.updateDt = v.updateDt.replace('T', ' ')
					return v
				})

				this.res = res
			})
		},
	},
	created() {
		this.search()
	},
}
</script>
